//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Component from '@nujek/ui/dist/runtime/base/Component.js'
const CustomNjImage = Component.extend({
  components: {},
  inheritAttrs: false,
  props: {
    src: {
      type: [String, Object],
      default: ''
    },
    disableLazyLoad: {
      type: Boolean,
      default: false
    },
    hideCopyright: {
      type: Boolean,
      default: false
    },
    focal: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: ''
    },
    copyright: {
      type: String,
      default: ''
    },
    usePlaceholder: {
      type: Boolean,
      default: true
    },
    placeholderImage: {
      type: String,
      default: ''
    },
    classes: {
      type: Object,
      default() {
        return {
          aspectRatio: '',
          image: 'object-cover'
        }
      }
    },
    imagePosition: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9
    }
  },
  computed: {
    srcImg() {
      return typeof this.src === 'string'
        ? this.src
        : this.src.imageSrc
        ? this.src.imageSrc
        : null
    },
    srcAlt() {
      if (this.src.alt) {
        return this.src.alt
      }
      return 'imagemage'
    },
    srcset() {
      return this.src?.srcset || null
    },
    breakpoint() {
      return {
        sm: 480,
        md: 768,
        lg: 1024,
        xl: 1440,
        xxl: Infinity
      }
    },

    getHeightWidth() {
      let width = ''
      let height = ''

      const screenWidth = process.client ? window?.screen?.width : null
      width =
        screenWidth === 'sm' || screenWidth === 'md'
          ? 720
          : screenWidth === 'lg'
          ? 1024
          : screenWidth === 'xl'
          ? 1440
          : 2160

      height = this.getHeightFromWidth(width)

      return { width, height }
    },
    hasAspectRatio() {
      return (
        /aspect-ratio-/.test(this.getThemeClass('aspectRatio')) &&
        'has-aspect-ratio'
      )
    },
    getClassesforFigure() {
      if (this.imagePosition === 'top') {
        return `flex items-start content-start ${this.getThemeClass('figure')}`
      } else if (this.imagePosition === 'center') {
        return `flex items-center content-center ${this.getThemeClass(
          'figure'
        )}`
      } else if (this.imagePosition === 'bottom') {
        return `flex items-end content-end ${this.getThemeClass('figure')}`
      } else {
        return `${this.getThemeClass('figure')}`
      }
    }
  },
  methods: {
    dynamicStyle() {
      if (this.focal.length !== 2) return {}
      return {
        objectPosition: `${this.focal[0]}% ${this.focal[1]}%`
      }
    },
    getHeightFromWidth(width) {
      if (!width) return 0
      return Math.round(width / this.aspectRatio)
    }
  }
})

export default CustomNjImage
