//
//
//
//
//
//
//
//
//
//
//

import isNumber from 'lodash/isNumber'

export default {
  props: {
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mapboxglAllowed () {
      return !!this.$store.state.gdpr.service?.mapboxgl
    },
    coords () {
      return isNumber(this.filter.distance?.latitude) && isNumber(this.filter.distance?.longitude) ? [this.filter.distance?.longitude, this.filter.distance?.latitude] : []
    }
  }
}
