import defaultsDeep from 'lodash/defaultsDeep'

const distanceHandler = (key, ctx, event) => {
  if (!event) {
    ctx.localFilter[key] = null
    return
  }
  const noLocationFound = (msg) => {
    ctx.localFilter[key] = null
    ctx.loading--
    if (msg) {
      alert(msg)
    }
  }

  const locationFound = (coords) => {
    const { latitude, longitude } = coords
    if (!latitude || !longitude) {
      ctx.localFilter[key] = null
    } else {
      ctx.localFilter[key] = { latitude, longitude }
    }
    ctx.loading--
  }

  if (!ctx.$geolocation.supported) {
    noLocationFound('Der Browser unterstütz diese Funktionalität nicht!')
    return
  }

  ctx.loading++
  return ctx.$geolocation.getCurrentPosition({
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 10000
  }).then(({ coords }) => {
    locationFound(coords)
  }).catch((error) => {
    switch (error.code) {
      case 1:
        noLocationFound('Erlauben Sie der Website Berechtigungen auf Ihren Standort!')
        break
      case 2:
        noLocationFound('Der Standort konnte nicht festgestellt werden!')
        break
      case 3:
        noLocationFound('Der Standort konnte nicht innerhalb der erlaubten Zeit festgestellt werden!')
        break
      default:
        noLocationFound()
        break
    }
  })
}

const DEFAULT_VALUES = {
  value: false,
  label: '',
  component: 'UtilsCheckButton'
}

const FILTER_SETTINGS = {
  categories: {
    label: 'Kategorien',
    value: null,
    component: 'CategoryDropdown',
    position: 0
  },
  open: {
    label: 'Heute geöffnet',
    position: 1
  },
  menu: {
    label: 'Mittagsmenü',
    position: 2
  },
  position: {
    label: 'In meiner Nähe',
    value: null,
    position: 3,
    convertToBoolean: true,
    handler: distanceHandler
  }
}

export const FILTER = Object.keys(FILTER_SETTINGS).reduce((p, c) => { p[c] = defaultsDeep({}, FILTER_SETTINGS[c], DEFAULT_VALUES); return p }, {})

export const DEFAULT_BUTTON_PROPS = {
  open: false,
  menu: false,
  distance: false
}

export const DEFAULT_PROPS = {
  open: false,
  menu: false,
  distance: null
}

export const DEFAULT_TITLES = {
  open: 'Heute geöffnet',
  menu: 'Mittagsmenü',
  distance: 'In meiner Nähe'
}

export const DEFAULT_HANDLERS = {
  distance: (ctx, event) => {
    if (!event) {
      ctx.localFilter.distance = null
      return
    }
    const noLocationFound = (msg) => {
      ctx.localFilter.distance = null
      ctx.buttonValue.distance = false
      ctx.loading--
      if (msg) {
        alert(msg)
      }
    }

    const locationFound = (coords) => {
      const { latitude, longitude } = coords
      if (!latitude || !longitude) {
        ctx.localFilter.distance = null
        ctx.buttonValue.distance = false
      } else {
        ctx.localFilter.distance = { latitude, longitude }
      }
      ctx.loading--
    }

    if (!ctx.$geolocation.supported) {
      noLocationFound('Der Browser unterstütz diese Funktionalität nicht!')
      return
    }

    ctx.loading++
    return ctx.$geolocation.getCurrentPosition({
      enableHighAccuracy: false,
      timeout: 10000,
      maximumAge: 10000
    }).then(({ coords }) => {
      locationFound(coords)
    }).catch((error) => {
      switch (error.code) {
        case 1:
          noLocationFound('Erlauben Sie der Website Berechtigungen auf Ihren Standort!')
          break
        case 2:
          noLocationFound('Der Standort konnte nicht festgestellt werden!')
          break
        case 3:
          noLocationFound('Der Standort konnte nicht innerhalb der erlaubten Zeit festgestellt werden!')
          break
        default:
          noLocationFound()
          break
      }
    })
  }
}

export const FILTER_ARRAY = () => {
  // console.log(DEFAULT_PROPS, )
  return Object.entries(DEFAULT_PROPS).reduce((p, [key]) => {
    p.push({ key, value: DEFAULT_TITLES[key] })
    return p
  }, [])
}

export default {
  DEFAULT_PROPS,
  DEFAULT_TITLES,
  FILTER_ARRAY,
  DEFAULT_HANDLERS,
  FILTER
}
