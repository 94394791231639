//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    image: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    link: {
      type: [String, Array],
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    holiday: {
      type: Boolean,
      default: false
    },
    website: {
      type: String,
      default: ''
    },
    menu: {
      type: String,
      default: ''
    },
    hasMenu: {
      type: Boolean,
      default: false
    },
    menuStart: {
      type: String,
      default: ''
    }
  },
  computed: {
    menuTag () {
      return this.menu ? 'a' : 'span'
    },
    menuAttributes () {
      if (!this.menu) {
        return {}
      }
      return {
        target: '_blank',
        href: this.menu,
        class: 'inline-block underline underline-offset-2 p-1 z-10 relative external-link'
      }
    }
  },
  methods: {
    handleMenuClick (event) {
      if (this.menu) {
        event.stopPropagation()
      }
    }
  }
}
