//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getJoinedCategories (categories) {
      if (!categories || !Array.isArray(categories)) { return '' }
      return categories.map(category => category.content?.title || '').filter(Boolean).join(', ')
    },
    getLinks (website, menu, hasMenu) {
      if (website && hasMenu) {
        return [
          { key: 'Website', value: website },
          { key: 'Mittagsmenü', value: menu }
        ]
      }
      if (website) {
        return [
          { key: 'Website', value: website }
        ]
      }
      if (menu) {
        return [
          { key: 'Mittagsmenü', value: menu }
        ]
      }
      return ''
    }
  }
}
