import { render, staticRenderFns } from "./InnGrid.vue?vue&type=template&id=65f9287c&"
import script from "./InnGrid.vue?vue&type=script&lang=js&"
export * from "./InnGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SponsorCard: require('/vercel/path0/components/utils/SponsorCard.vue').default,Card: require('/vercel/path0/components/utils/Card.vue').default})
