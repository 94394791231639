//
//
//
//

export default {
  inheritAttrs: false,
  props: {
    src: {
      type: Object,
      default: () => ({})
    },
    classes: {
      type: Object,
      default: () => ({})
    },
    srcset: {
      type: String,
      default: ''
    },
    resize: {
      type: Object,
      default: () => ({
        width: '600',
        height: '0'
      })
    },
    srcSetSizes: {
      type: Array,
      default: () => [720, 1024, 1440, 2160]
    },
    noQualityLoss: {
      type: Boolean,
      default: false
    },
    disableLazyLoad: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    imagePosition: {
      type: String,
      default: ''
    }
  },
  computed: {
    getFilterString () {
      let filterString = 'filters'
      if (this.filters.length) {
        this.filters.forEach(function (filter) {
          if (filter.name && filter.value) {
            filterString = `${filterString}:${filter.name}(${filter.value})`
          }
        })
      }

      return filterString !== 'filters' ? filterString : ''
    },
    image () {
      return {
        imageSrc: this.imageSrc(this.resize),
        srcset: this.srcSet,
        alt: this.src.alt
      }
    },
    focal () {
      if (!this.src?.focus || !this.src?.filename) { return [] }
      const focus = this.src.focus.split(':')[0].split('x')
      const imageSize = this.src.filename.split('/')[5].split('x')
      return [Math.round((focus[0] / imageSize[0]) * 100), Math.round((focus[1] / imageSize[1]) * 100)]
    },
    srcSet () {
      return this.srcSetSizes
        .map((size) => {
          return `${this.imageSrc({
            width: size,
            height: 0
          })} ${size}w`
        })
        .join(', ')
    }
  },
  methods: {
    getHeightWidth (heightWidthObj) {
      return !this.noQualityLoss
        ? `${Math.round(heightWidthObj.width * 0.7)}x${heightWidthObj.height}`
        : '1440x0'
    },
    imageSrc (heightWidthObj) {
      const filename = this.src?.filename || null
      return filename && this.getFilterString !== ''
        ? `${filename}/m/${this.getHeightWidth(heightWidthObj)}/${
            this.getFilterString
          }`
        : filename
          ? `${filename}/m/${this.getHeightWidth(heightWidthObj)}`
          : ''
    }
  }
}
