//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import defu from 'defu'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { FILTER } from './utils'

export default {
  data () {
    return {
      loading: 0,
      FILTER,
      localFilter: Object.keys(FILTER).reduce((p, c) => { p[c] = FILTER[c].value; return p }, {})
    }
  },
  fetch () {
    this.parsUrlToFilter()
  },
  computed: {
    ...mapGetters({
      view: 'view/getterViewId'
    }),
    urlFilter () {
      return this.$route.query.filter
    }
  },
  watch: {
    loading (newVal) {
      this.$emit('loading', newVal)
    },
    localFilter: {
      deep: true,
      handler (newVal) {
        if (isEqual(this.filter, newVal)) { return }
        this.$emit('change', newVal)
      }
    },
    urlFilter () {
      this.parsUrlToFilter()
    }
  },
  // mounted () {
  //   this.$nextTick(() => {
  //     this.parsUrlToFilter()
  //   })
  // },
  methods: {
    pushFilterToUrl () {
      const filterQuery = Object.entries(this.localFilter).map(([key, value]) => {
        if ([null, undefined].includes(value)) {
          return ''
        }
        if (['object', 'string', 'number'].includes(typeof value)) {
          return `${key}+${JSON.stringify(value)}`
        }
        if (!value) {
          return ''
        }
        return key
      }).filter(Boolean).join(';')
      if (!filterQuery) {
        const query = cloneDeep(this.$route.query)
        delete query.filter
        this.$router.push({ path: this.$route.path, query })
      } else {
        this.$router.push(defu({ query: { filter: filterQuery } }, this.$route))
      }
    },
    parsUrlToFilter () {
      let filterObject = {}
      if (this.urlFilter) {
        filterObject = this.urlFilter.split(';').map(elem => elem.split('+')).reduce((p, c) => {
          p[c[0]] = c[1] !== undefined ? JSON.parse(c[1]) : true
          return p
        }, {})
      }
      const result = {}
      Object.keys(this.FILTER).forEach((key) => {
        if (filterObject[key]) {
          result[key] = filterObject[key]
        } else {
          result[key] = FILTER[key].value
        }
      })
      this.localFilter = result
    },
    async handleChange (key, event) {
      this.loading++
      if (!FILTER[key].handler) {
        this.localFilter[key] = event
      } else {
        await FILTER[key].handler(key, this, event)
      }
      this.loading--
      this.pushFilterToUrl()
    }
  }
}
