//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import InfiniteLoading from 'vue-infinite-loading'
import { FILTER } from '~/components/utils/Filter/utils'

const WEEKDAY_MAP = {
  0: 6,
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5
}

const checkDistance = (mk1, mk2) => {
  const R = 6371.0710 // Radius of the Earth in km
  const rlat1 = mk1.latitude * (Math.PI / 180) // Convert degrees to radians
  const rlat2 = mk2.latitude * (Math.PI / 180) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180) // Radian difference (longitudes)

  const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))
  return d
}

export default {
  components: {
    InfiniteLoading
  },

  props: {
    blok: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      firstFetchComplete: false,
      stories: [],
      filter: {},
      loading: 0,
      value: null,
      categories: '',
      timeout: null,
      view: 'list',
      infinitePage: 1,
      infinitePerPage: 18,
      infiniteId: +new Date()
    }
  },
  async fetch () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
    if (!this.source) { return }
    let initCategories = ''
    if (!this.firstFetchComplete && this.$route?.query?.filter) {
      this.parsUrlToFilter()
      const categories = this.$route.query.filter.split(';').map(elem => elem.split('+')).find(elem => elem[0] === 'categories')?.[1]
      if (categories) {
        initCategories = JSON.parse(categories).join(',')
      }
    }
    const version = this.$config.isDev ? 'draft' : 'published'
    try {
      const payload = {
        version,
        is_startpage: false,
        starts_with: this.source,
        sort_by: 'content.title:asc',
        resolve_relations: 'Inn.categories',
        ...(initCategories || this.categories
          ? {
              filter_query: {
                categories: {
                  all_in_array: initCategories || this.categories
                }
              }
            }
          : null),
        cv: new Date().getTime(),
        per_page: 100
      }
      const response = await this.$storyapi.getStories(payload)
      const stories = this.parseStories(response?.data?.stories)
      this.stories = stories
      if (!this.firstFetchComplete) {
        this.firstFetchComplete = true
      }
      if (process.client) {
        this.setInterval()
      }
    } catch (error) {
      console.error(error)
    }
  },
  computed: {
    ...mapGetters({
      viewId: 'view/getterViewId',
      sponsors: 'sponsors/getterSponsors'
    }),
    source () {
      return this.blok.source?.full_slug || ''
    },
    mapMarkers () {
      return this.filteredStories.filter(story => !!story.content?.latitude && !!story.content?.longitude).map(story => ({
        latitude: story.content.latitude,
        longitude: story.content.longitude,
        title: story.content.title,
        url: `/${story.full_slug}`,
        holidays: story.holidaysBoolean,
        openNow: story.openNow
      }))
    },
    filteredStories () {
      let filtered = cloneDeep(this.stories)
      if (this.filter.menu) {
        filtered = filtered.filter(story => story.content.lunchMenu)
      }
      if (this.filter.open) {
        filtered = filtered.filter((story) => {
          if (story.holidaysBoolean) {
            return false
          }
          return story.openToday
        })
      }
      return filtered
    },
    sortedStories () {
      const sorters = []
      if (this.filter.position && typeof this.filter.position === 'object') {
        sorters.push((o) => {
          const [lat, long] = [Number(o.content?.latitude), Number(o.content?.longitude)]
          if (isNaN(lat) || isNaN(long)) {
            return Infinity
          }
          return checkDistance(this.filter.position, { latitude: lat, longitude: long })
        })
      }
      sorters.push((o) => {
        if (o.holidaysBoolean) {
          return 1
        }
        return o.openNow ? 0 : 1
      })
      return sortBy(this.filteredStories, sorters)
    },
    storiesAndSponsors () {
      const res = cloneDeep(this.sortedStories)
      if (!this.sponsors.length || !res.length) { return res }
      const calcSponsors = this.sponsors.slice(0, Math.floor(res.length / 9))
      for (let i = 0; i < calcSponsors.length; i++) {
        const sponsor = calcSponsors[i]
        res.splice((9 * i) + (i + 1), 0, sponsor)
      }
      return res
    },
    gridList () {
      return this.storiesAndSponsors.slice(0, this.infinitePerPage * this.infinitePage)
    }
  },
  watch: {
    filter: {
      deep: true,
      handler (newVal, oldVal) {
        this.resetInfinitePage()
        const newCat = (newVal.categories || []).join(',')
        const oldCat = (oldVal.categories || []).join(',')
        if (newCat !== oldCat) {
          this.categories = newCat
          this.$fetch()
        }
      }
    },
    viewId (newVal) {
      this.view = newVal
    }
  },
  mounted () {
    this.view = this.viewId
    this.$fetch()
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  },
  methods: {
    parsUrlToFilter () {
      let filterObject = {}
      const urlFilter = this.$route.query.filter
      if (urlFilter) {
        filterObject = urlFilter.split(';').map(elem => elem.split('+')).reduce((p, c) => {
          p[c[0]] = c[1] !== undefined ? JSON.parse(c[1]) : true
          return p
        }, {})
      }
      const result = {}
      Object.keys(FILTER).forEach((key) => {
        if (filterObject[key]) {
          result[key] = filterObject[key]
        } else {
          result[key] = FILTER[key].value
        }
      })
      this.filter = result
    },
    resetInfinitePage () {
      this.infinitePage = 1
      this.infiniteId = +new Date()
    },
    increaseInfinitePage ($state) {
      if (this.infinitePage * this.infinitePerPage < this.storiesAndSponsors.length) {
        this.infinitePage += 1
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    handleFilterChange (event) {
      this.filter = cloneDeep(event)
    },
    setInterval () {
      if (this.interval) { return }
      this.interval = setInterval(() => {
        this.parseStories(this.stories)
      }, 60000 * 1) /** check every minute if restaurante is open */
    },
    parseStories (stories) {
      if (!stories || !Array.isArray(stories)) {
        return []
      }
      return stories.map((story) => {
        story.holidaysBoolean = this.parseHolidaysToBoolean(story.content?.holidays?.list)
        const { openToday, openNow } = this.parseOpeningHoursToBoolean(story.content?.openingHoursGeneral?.days)
        story.openToday = openToday
        story.openNow = openNow
        return story
      })
    },
    parseOpeningHoursToBoolean (openingHours) {
      const currentMoment = this.$moment()
      const currentFormat = currentMoment.format('HH:mm')
      const currentWeekday = currentMoment.day()
      const result = {
        openToday: false,
        openNow: false
      }
      if (!openingHours) {
        return result
      }
      const times = openingHours[WEEKDAY_MAP[currentWeekday]]?.times
      if (!times || !Array.isArray(times)) {
        return result
      }
      result.openToday = times.some((time) => {
        const start = this.$moment(time.start, 'HH:mm')
        const end = this.$moment(time.end, 'HH:mm')
        return !(!start.isValid() || !end.isValid())
      })
      result.openNow = times.some((time) => {
        const start = this.$moment(time.start, 'HH:mm')
        const end = this.$moment(time.end, 'HH:mm')
        if (!start.isValid() || !end.isValid()) { return false }
        return start.format('HH:mm') <= currentFormat && currentFormat <= end.format('HH:mm')
      })
      return result
    },
    parseHolidaysToBoolean (holidays) {
      if (!holidays || !Array.isArray(holidays)) { return false }
      holidays = holidays.filter(holiday => !!holiday.from?.day && !!holiday.from?.month && !!holiday.to?.day && !!holiday.to?.month)
      if (!holidays.length) { return false }
      const now = this.$moment().format('MM.DD')
      return holidays.some((holiday) => {
        const from = this.$moment(`${holiday.from.month}.${holiday.from.day}`, 'M.D').format('MM.DD')
        const to = this.$moment(`${holiday.to.month}.${holiday.to.day}`, 'M.D').format('MM.DD')
        return from <= now && now <= to
      })
    },
    handleCategoryChange (list) {
      if (!list) { this.categories = '' } else { this.categories = list.map(item => item.uuid).join(',') }
      this.$fetch()
    },
    handleClick (slug) {
      this.$router.push(slug)
    }
  }
}
