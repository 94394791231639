//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  props: {
    markers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      map: null,
      markerList: [],
      bounds: null,
      isMounted: false
    }
  },
  computed: {
    hasMapboxToken () {
      return !!this.$config.MAPBOX_TOKEN
    },
    mapboxSupported () {
      return mapboxgl.supported()
    }
  },
  watch: {
    markers: {
      deep: true,
      handler () {
        this.removeMarkersFromMap()
        this.addMarkersToMap()
      }
    }
  },
  mounted () {
    if (!this.hasMapboxToken || !this.mapboxSupported) { return }
    this.$nextTick(() => {
      const container = this.$refs.map
      if (!container) { return }
      mapboxgl.accessToken = this.$config.MAPBOX_TOKEN
      this.map = new mapboxgl.Map({
        container,
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [13.3457347, 47.6964719], // starting position [lng, lat]
        zoom: 6,
        cooperativeGestures: true,
        locale: {
          'GeolocateControl.FindMyLocation': 'Meinen Standort finden',
          'GeolocateControl.LocationNotAvailable': 'Standort nicht verfügbar',
          'ScrollZoomBlocker.CtrlMessage': 'Benutze Strg + Scroll um die Karte zu zoomen',
          'ScrollZoomBlocker.CmdMessage': 'Benutze ⌘ + Scroll um die Karte zu zoomen',
          'TouchPanBlocker.Message': 'Benutze zwei Finger um die Karte zu bewegen'
        }
      })
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: false
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true
      })
      this.map.addControl(geolocate)
      this.addMarkersToMap()
      this.map.once('load', () => {
        this.map.setLayoutProperty('country-label', 'text-field', [
          'get',
          'name_de'
        ])
        // geolocate.trigger()
      })
      this.map.on('zoom', this.zoomHandler)
      this.map.on('click', this.clickHandler)
      this.isMounted = true
    })
  },
  beforeDestroy () {
    this.map.off('zoom', this.zoomHandler)
    this.map.off('click', this.clickHandler)
  },
  methods: {
    clickHandler (event) {
      const target = event.originalEvent.target
      const res = this.markerList.find((el) => {
        const elDiv = el.marker.getElement()
        return elDiv.contains(target)
      })
      if (res) {
        this.$router.push(res.item.url)
      }
    },
    zoomHandler () {
      const visible = this.map.getZoom() > 12
      this.markerList.forEach((element) => {
        if (visible && element.popup) {
          element.popup.addTo(this.map)
        } else if (!visible && element.popup) {
          element.popup.remove()
        }
      })
    },
    removeMarkersFromMap () {
      this.markerList.map((obj) => {
        if (obj.popup) {
          obj.popup.remove()
        }
        obj.marker.remove()
        return obj
      })
      this.markerList = []
    },
    addMarkersToMap () {
      if (!this.map || !this.markers.length) { return }
      this.bounds = new mapboxgl.LngLatBounds()
      this.markerList = this.markers.map((marker) => {
        let popup
        const markerOptions = {
          color: '#be4d25'
        }
        if (marker.holidays) {
          popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false, anchor: 'top', className: 'holiday-popup' }).setLngLat([marker.longitude, marker.latitude]).setText('Im Urlaub')
          if (this.map.getZoom() > 12) {
            popup.addTo(this.map)
          } else {
            popup.remove()
          }
        } else if (marker.openNow) {
          markerOptions.color = '#49be25'
        }
        const m = new mapboxgl.Marker(markerOptions).setLngLat([marker.longitude, marker.latitude]).addTo(this.map)
        this.bounds.extend(m.getLngLat())
        return { marker: m, popup, item: marker }
      })
      this.map.fitBounds(this.bounds, { duration: this.isMounted ? 500 : 0, padding: { top: 45, bottom: 25, left: 25, right: 25 } })
    }
  }
}
