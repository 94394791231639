//
//
//
//
//
//
//
//
//
//

import { hasProtocol } from 'ufo'
export default {
  props: {
    featuredImage: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: String,
      default: ''
    }
  },
  computed: {
    ensuredLink () {
      if (hasProtocol(this.link)) { return this.link }
      return 'https://' + this.link
    }
  }
}
